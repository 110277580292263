import axiosInstance from "./api";
import TokenService from "./token.service";
import router from '@/router'
import store from '@/store'
const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      }

      var merchantCode = store.state.merchantCode;
      var merchantId = localStorage.getItem('MERCHANT_ID_' + merchantCode, 0)

      config.headers["MerchantCode"] = merchantCode;  // for Spring Boot back-end

      config.headers["MerchantId"] = merchantId;  // for Spring Boot back-end

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      var code = store.state.merchantCode;
      var codeSlat = code ? `/${code}` : '';

      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const loginFormOnlineOrdering = url.searchParams.get('branch_code');
      const tableFormOnlineOrdering = url.searchParams.get('table_code');
      const fromParamOnlineOrdering = url.searchParams.get('from');
      const queueParamOnlineOrdering = url.searchParams.get('queue');

      if (err.response.status === 401 && !originalConfig._retry) {
        TokenService.removeUser();
        if (loginFormOnlineOrdering !== null) {
          let newUrl = `${codeSlat}/sign-in?branch_code=${loginFormOnlineOrdering}&from=${fromParamOnlineOrdering}&queue=${queueParamOnlineOrdering}`;

          if (tableFormOnlineOrdering) {
            newUrl += '&table_code=' + tableFormOnlineOrdering;
          }

          router.push(newUrl);
        } else {
          router.push(codeSlat + '/sign-in');
        }
      }
      if (err.response.status === 403 && !originalConfig._retry) {
        if (loginFormOnlineOrdering !== null) {
          let newUrl = `${codeSlat}/sign-in?branch_code=${loginFormOnlineOrdering}&from=${fromParamOnlineOrdering}&queue=${queueParamOnlineOrdering}`;

          if (tableFormOnlineOrdering) {
            newUrl += '&table_code=' + tableFormOnlineOrdering;
          }

          router.push(newUrl);
        } else {
          router.push(codeSlat + '/sign-in');
        }
      }
      // if (originalConfig.url !== "/auth/login" && err.response) {
      //   // Access Token was expired
      //   if (err.response.status === 401 && !originalConfig._retry) {
      //     originalConfig._retry = true;

      //     try {
      //       const rs = await axiosInstance.post("/auth/refreshtoken", {
      //         refreshToken: TokenService.getLocalRefreshToken(),
      //       });

      //       const { accessToken } = rs.data;

      //       store.dispatch('auth/refreshToken', accessToken);
      //       TokenService.updateLocalAccessToken(accessToken);

      //       return axiosInstance(originalConfig);
      //     } catch (_error) {
      //       return Promise.reject(_error);
      //     }
      //   }
      // }

      return Promise.reject(err);
    }
  );
};

export default setup;